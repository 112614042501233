import React, { useState, useEffect } from 'react';
import { useWeb3ModalAccount } from '@web3modal/ethers/react';
import { BrowserProvider } from 'ethers';
import axios from 'axios';
import { stakers } from './stakers';
import { owners } from './owners';
import './App.css';
import './web3modalConfig';
import Cookies from 'js-cookie';
import {
  Stepper, Step, StepLabel, Button, Typography, Container, Box, ThemeProvider, Fade
} from '@mui/material';
import theme from './theme'; // Import the custom theme

Cookies.set('cookieName', 'cookieValue', { sameSite: 'None', secure: true });

const steps = [
  'Connect your Wallet',
  'Verify Ownership',
  'Submit Form',
];

const App = () => {
  const { address, isConnected } = useWeb3ModalAccount();
  const [message, setMessage] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (isConnected && activeStep === 0) {
      handleNext();
    }
  }, [isConnected, activeStep]);

  console.log("Connected Successfully: ", address);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const verifyOwnership = async () => {
    if (!isConnected) {
      setMessage('Please connect your wallet first.');
      return;
    }

    const normalizedAddress = address.toLowerCase();
    const normalizedStakers = stakers.map(addr => addr.toLowerCase());
    const normalizedOwners = owners.map(addr => addr.toLowerCase());

    if (normalizedStakers.includes(normalizedAddress) || normalizedOwners.includes(normalizedAddress)) {
      try {
        const messageToSign = "Please sign this message to verify your wallet ownership.";
        const provider = new BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const signature = await signer.signMessage(messageToSign);

        console.log('Signature:', signature);

        // Send the signature to the backend for verification
        const response = await axios.post('https://aces-backend-cc6161264ac6.herokuapp.com/verify-signature', {
          connectedWallet: address,
          signature,
          message: messageToSign
        });

        if (response.data.verified) {
          if (response.data.registered) {
            setMessage('You are already registered!');
          } else {
            handleNext();
          }
        } else {
          setMessage('Failed to verify ownership. Please try again.');
        }
      } catch (error) {
        console.error('Signature request failed', error);
        setMessage('Failed to verify signature. Please try again.');
      }
    } else {
      setMessage('You are not an ACES holder.');
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Fade in={true}>
            <div>
              <w3m-button></w3m-button>
            </div>
          </Fade>
        );
      case 1:
        return (
          <Fade in={true}>
            <div>
              <Typography>We need to verify that you are the true owner of this wallet by signing a message via your wallet.</Typography>
              <Button variant="contained" onClick={verifyOwnership} sx={{ mt: 2 }}>VERIFY</Button>
              <Typography sx={{ mt: 2 }}>{message}</Typography>
            </div>
          </Fade>
        );
      case 2:
        return (
          <Fade in={true}>
            <div>
                <div class="google-form-container">
                  <iframe title="formFrame" src="https://docs.google.com/forms/d/e/1FAIpQLSfQtHN89qsIC2mqnB9YY_GXr6sxvIU_Ly7vZrTAdMm9NTBoEA/viewform?embedded=true?usp=pp_url&entry.1891284800=0x48E031E660afe19261D0711cc2390668D54091B5" width="100%" height="3100" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
            </div>
          </Fade>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <img src="https://theaces.xyz/images/the-aces-full-mark.svg" alt="Logo" className="App-logo" />
          <nav className={`App-nav ${menuOpen ? 'open' : ''}`}>
            <w3m-button></w3m-button>
          </nav>
          <div className="hamburger" onClick={toggleMenu}>
            &#9776;
          </div>
        </header>
        <div className="App-container">
          <h1>BIG3 Power Tickets for Aces NFT Holders</h1>
          <Container>
            <Box sx={{ width: '100%', mt: 4 }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel StepIconProps={{
                      completed: activeStep > index,
                      active: activeStep === index
                    }}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box sx={{ mt: 2, mb: 2 }}>
                {renderStepContent(activeStep)}
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
